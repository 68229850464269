@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&family=Roboto&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.backshadow{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.backshadow .custom-modal{
  width: 900px;
  height: 600px;
  background-color: #fff;
  position: relative;
}

.delete-icon{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
  font-family: 'Raleway', sans-serif;
  z-index: 2;
}