.containers{
    
    height:  200px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/xdPXSS1v/games.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;

  }