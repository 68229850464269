.homecolor{
    
    background-color: rgb(218, 223, 255);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(224, 78, 78);
}

.textcolor h5{
    color: rgb(196, 7, 7);
    
}

.buttonhome{
    background-color: #1489d1; /* Green */
  border: none;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 11px;
}



.btn-grad30 {
    background-image: linear-gradient(to right, #8c5a0f 0%, #584f11  51%, #0a4c78  100%);
    margin: 0px;
    padding: 17px 32px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
  }
  
  .btn-grad30:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }


  .btn-grad31 {
    background-image: linear-gradient(to right, #4f0321 0%, #0a6b65  51%, #0a4c78  100%);
    margin: 0px;
    padding: 17px 32px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
    font-size: 24px;
  }
  
  .btn-grad31:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }


  .resultnew2024{
    background-color: #0a4c78;
  }

  .buttonyellow{
    background-color: #ffc800; /* Green */
    border: none;
    color: rgb(6, 38, 102);
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
   border-radius: 8px;
  }

  .buttonskyblue{
    background-color: #87CEFA; /* Green */
    border: none;
    color: rgb(5, 19, 46);
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
   border-radius: 8px;
  }

  .buttonpink{
    background-color: #B50F69; /* Green */
    border: none;
    color: rgb(248, 250, 255);
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
   border-radius: 8px;
  }
  