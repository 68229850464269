.gamesoly{
    
    background-color: rgb(87, 93, 99);
    padding: 0%;
    color: white;
    text-decoration-color:  rgb(224, 78, 78);
}

.texts{
    text-align: justify;
    text-justify: inter-word;
}