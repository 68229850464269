

.button1 {
    background-color:  rgb(131, 28, 131);
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
  }


  .btn-grad15 {
    background-image: linear-gradient(to right, #cb0000 0%, #f3ff05  51%, #2b5876  100%);
    margin: 0px;
    padding: 9px 29px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 12px;
    display: block;
  }
  
  .btn-grad15:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

 


  btn-grad16{
    background-image: linear-gradient(to right, #cba900 0%, #14176e  51%, #e0f2fe  100%);
    margin: 0px;
    padding: 9px 29px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 12px;
    display: block;
  }

  .blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }