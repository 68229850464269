.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Post{
  max-width: 800px;
  width: 100%;
  display: table;
  margin: 0 auto;
  padding: 20px 50px;
  
}


.container{
  font-family: 'Josefin Sans', sans-serif;
font-family: 'Kanit', sans-serif;
font-family: 'Maven Pro', sans-serif;
font-family: 'Noto Sans', sans-serif;
font-family: 'Prompt', sans-serif;
}


/* 
a {
  text-decoration: none !important;
  color: inherit !important;
  
} */

a {
  text-decoration: none !important;
  color: inherit !important;
  
}



.btn-grad16 {
  background-image: linear-gradient(to right, #ffc811 0%, #37a2ea  51%, #2b5876  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 12px;
  display: block;
}

.btn-grad16:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}




.btn-grad29 {
  background-image: linear-gradient(to right, #510101 0%, #935306  51%, #edd4e6  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 12px;
  display: block;
}

.btn-grad16:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.backnew{
  background-color: #dee9f0;
}

.bookbackground{
  background-color: #d1dbde;
}




.btn-grad28 {
  background-image: linear-gradient(to right, #015a72 0%, #c28c17  51%, #0a4c78  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 12px;
  display: block;
}

.btn-grad28:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.foundationnadia{
  background-color: #c8dade;
}


.btn-grad45 {
  background-image: linear-gradient(to right, #016972 0%, #1246c9  51%, #0a4c78  100%);
  margin: 0px;
  padding: 11px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 3px;
  display: block;
}

.btn-grad45:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}







.btn-grad134 {
  background-image: linear-gradient(to right, #6a4608 0%, #6b0303  51%, #edd4e6  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 12px;
  display: block;
}

.btn-grad134:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.colorback{
  background-color: #cbf0fa;
}

.buttonwhite{
  background-color: #ffffff; /* Green */
  border: none;
  color: rgb(6, 38, 102);
  padding: 7px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
 border-radius: 8px;
}